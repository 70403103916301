import React, { useState } from 'react';
import { RadioGroup, Radio, Button, FormControl, FormLabel, Input, Typography, Box, Checkbox, FormHelperText, Link, IconButton, Divider, LinearProgress } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import OrderService from './OrderService';
import contentData from './content.json';
import { ArrowBack } from '@mui/icons-material';

const Quiz = ({ questions }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [userInfo, setUserInfo] = useState({ phone: '', politics: true });
    const [isQuizComplete, setIsQuizComplete] = useState(false);

    const navigate = useNavigate();
    const handleRedirect = () => {
        window.location.href = '/thank_you'
    };

    const handleAnswerChange = (answer) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questions[currentQuestionIndex].id]: answer,
        }));

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsQuizComplete(true);
        }
    };

    const handleNextQuestion = () => {
        if (!answers[questions[currentQuestionIndex].id]) {
            return;
        }

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsQuizComplete(true);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };


    const handleUserInfoChange = (e) => {
        const { name, value, type, checked } = e.target;

        const newValue = type === 'checkbox' ? checked : value;

        setUserInfo((prevInfo) => ({
            ...prevInfo,
            [name]: newValue,
        }));
    };

    const handleSubmit = async () => {
        if (userInfo.phone.length === 0 || (contentData.needsPolicy && !userInfo.politics)) {
            return;
        }

        const formattedAnswers = questions.map((question) => {
            const answer = answers[question.id] || 'Не выбрано';
            return `${question.question} — ${answer}`;
        }).join('. ');

        await OrderService.createOrder(userInfo.phone, "", formattedAnswers);

        handleRedirect();
    };

    // Если массив вопросов пуст или квиз завершен, показываем форму ввода данных пользователя
    if (questions.length === 0 || isQuizComplete) {
        return (
            <div style={{ color: 'white', maxWidth: '480px' }}>
                <Typography level="h2" sx={{ color: 'white', textAlign: 'center' }}>Оставьте заявку прямо сейчас! И мы закрепим за вашим номером скидку 🏷️ и подарок 🎁</Typography>
                <Input
                    placeholder="Номер телефона..."
                    name="phone"
                    type="number"
                    value={userInfo.phone}
                    onChange={handleUserInfoChange}
                    style={{ marginTop: '26px', borderRadius: '250px', padding: 8 }}
                />
                {contentData.needsPolicy && (
                    <FormControl size="sm" sx={{ marginTop: '14px' }}>
                        <Checkbox
                            name='politics'
                            checked={userInfo.politics}
                            onChange={handleUserInfoChange}
                            sx={{color: 'white'}}
                            color="warning"
                            label={
                                <React.Fragment>
                                    Я прочитал и согласен с{' '}
                                    <Typography sx={{ fontWeight: 'md' }}>политикой конфиденциальности</Typography>.
                                </React.Fragment>
                            }
                        />
                        <FormHelperText>
                            <Typography sx={{color: 'white'}} level="body-sm">
                                Прочитайте нашу <Link color="warning" href="https://citrustele.by/documents/politics.html">политику конфиденциальности</Link>.
                            </Typography>
                        </FormHelperText>
                    </FormControl>
                )}
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 14,
                        left: 0,
                        right: 0,
                        marginLeft: 4,
                        marginRight: 4,
                        textAlign: 'center'
                    }}
                >
                    <Button variant="solid" color="warning" onClick={handleSubmit} sx={{
                        marginTop: '20px',
                        width: '200px',
                        borderRadius: '250px',
                        fontSize: 14,
                        px: 4,
                        py: 2,
                        textAlign: 'center'
                    }}
                    >
                        Отправить
                    </Button>
                </Box>
            </div >
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <>
            <div style={{ position: 'fixed', top: 40, left: 60, right: 60 }}>
                <LinearProgress variant='plain' sx={{ width: '100%' }} color="warning" determinate value={Math.round((currentQuestionIndex + 1) / questions.length * 100)} />
            </div>
            <div style={{ textAlign: 'left', padding: 4, maxWidth: '600px' }}>
                <Typography level="h3" sx={{ mb: 2, color: 'white' }}>{currentQuestion.question}</Typography>
                <FormControl>
                    <RadioGroup
                        value={answers[currentQuestion.id] || ''}
                        onChange={(e) => handleAnswerChange(e.target.value)}
                    >
                        {currentQuestion.options.map((option, index) => (
                            <Radio
                                key={index}
                                value={option}
                                label={option}
                                variant="outlined"
                                sx={{ color: 'white' }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 14,
                        left: 0,
                        right: 0,
                        marginLeft: 4,
                        marginRight: 4,
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                >
                    <IconButton
                        onClick={handlePreviousQuestion}
                        variant='plain'
                        color="warning"
                        sx={{
                            marginTop: '20px',
                            borderRadius: '250px',
                            fontSize: 22,
                            px: 2,
                            py: 2,
                            textAlign: 'center',
                            aspectRatio: '1/1',
                            height: '53px',
                            mr: 2
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Button
                        variant="solid" color="warning"
                        onClick={handleNextQuestion}
                        sx={{
                            marginTop: '20px',
                            width: '200px',
                            borderRadius: '250px',
                            fontSize: 14,
                            px: 4,
                            py: 2,
                            textAlign: 'center'
                        }}
                    >
                        Далее
                    </Button>
                </Box>
            </div>
        </>
    );
};

export default Quiz;
